<div
  class="modal"
  [ngClass]="{'show': isVisible}"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="emailQuoteLabel">
          {{isTravelerFormInvalid == true? 'Alert':'Save & Email Quote'}}
        </h2>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="closeModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isTravelerFormInvalid">
            <span>Please validate traveler information and try again.</span>         
        </div>
        <div *ngIf="!isTravelerFormInvalid">
          <form
            [formGroup]="emailForm"
            class="row row-cols-lg-auto g-3 align-items-center"
          >
          <div class="row">
            <div class="col-7">
              <div class="input-group">
                <div class="input-group-text">
                  <i class="fa-regular fa-envelope"></i>
                </div>
                <input type="email" class="form-control" placeholder="Enter Email Address" [(ngModel)]="emailId" name="emailId"
                  formControlName="emailAddress" />
              </div>
            </div>
            <div class="col-5">
              <button type="submit"
                [disabled]="emailAddress?.errors?.['invalidEmail'] || emailForm.get('emailAddress')?.errors?.['required'] || isButtonDisabled"
                class="btn btn-info smallfix" (click)="sendEmail()">
                Save & Email
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ng-container *ngIf="
                        emailForm.get('emailAddress')?.invalid &&
                        (emailForm.get('emailAddress')?.dirty ||
                          emailForm.get('emailAddress')?.touched)
                      ">
                <small class="text-danger">
                  <span *ngIf="emailAddress?.errors?.['invalidEmail']">
                    Please enter a valid email address.
                  </span>
                  <span *ngIf="emailForm.get('emailAddress')?.errors?.['required']">
                    Please enter an email address.
                  </span>
                </small>
              </ng-container>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Email Disclaimer -->
<div
  class="modal"
  [ngClass]="{'show': isEmailDisclaimer}"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          style="float: right;box-shadow: none;"
          (click)="closeEmailDisclaimerModal()"
        ></button>
        <p style="padding-right: 20px;">
          Your quote is now being emailed, please check your spam folder. If you
          have a Hotmail, Bigpond, Gmail or Outlook account the quote email may
          be blocked by your firewall or email provider. Please contact us if
          you have not received the quote email within 24 hours. NOTE: If your
          quote does not include cover for declared medical conditions, an
          increase in premium may apply. Please proceed to the medical conditions
          section to apply for cover.
        </p>
      </div>
    </div>
  </div>
</div>

<p-toast></p-toast>
