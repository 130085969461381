<main class="main">
  <app-loading-mask *ngIf="showLoadingMask$ | async"></app-loading-mask>
  <router-outlet></router-outlet>
  <p-toast />
  <!-- Modal Email Quote-->
  <app-email-quote></app-email-quote>
      <!-- Modal Alert Disclaimer -->
<!-- <div class="modal" [ngClass]="{ show: showPaymentGatewayAlert }">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          style="float: right; box-shadow: none"
          (click)="closePaymentGatewayAlert()"
        ></button>
        <p style="padding-right: 20px">
          We apologise. Our payment gateway provider PayPal is currently experiencing technical difficulties. Whilst we can still provide you with a quote, you will encounter an error on the payment page, and we are unable to sell you a policy at this time.
          Please know that we are working with PayPal to resolve this issue as quickly as possible. We apologise for any inconvenience this may cause. Please check back on this page later, as we hope to resolve this problem soon
        </p>
      </div>
    </div>
  </div>
</div> -->
</main>
